
<template>
<div class="row tgju-widgets-row"> 
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>

        <div class="market-profile-inner">
            <ul class="m-pointer">
                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">عنوان</div>
                        <div class="profile-inner-table-value">مقدار</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>
                <li :class="activeLi == 100 || activeLi == 0 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(100)">
                        <div class="profile-inner-table-name">‌میانگین متحرک (EMA)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item" v-for="(item, index) in stock_indicator_values.ema" :key="index" v-if="stock_indicator_values.ema">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی {{ index }} روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(item) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 101 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(101)">
                        <div class="profile-inner-table-name">‌میانگین متحرک (SMA) </div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">

                        <div class="profile-inner-table-item" v-for="(item, index) in stock_indicator_values.sma" :key="index" v-if="stock_indicator_values.sma">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دوره زمانی {{ index }} روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(item) }}</div>
                            </div>
                        </div> 

                    </div>
                </li>
                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name"> سطوح ‌حمایت و مقاومت</div>
                    </div>
                </li>
                <li :class="activeLi == 103 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(103)">
                        <div class="profile-inner-table-name">سطح حمایت 3 (S3)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.s3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 104 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(104)">
                        <div class="profile-inner-table-name">سطح حمایت 2 (S2)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.s2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 105 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(105)">
                        <div class="profile-inner-table-name">سطح حمایت 1 (S1)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.s1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 106 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(106)">
                        <div class="profile-inner-table-name">نقطه پیوت (PP)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.pp) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.demark.pp) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 107 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(107)">
                        <div class="profile-inner-table-name">مقاومت 1 (R1)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.r1) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.demark.r1) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 108 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(108)">
                        <div class="profile-inner-table-name">مقاومت 2 (R2)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.r2) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
                <li :class="activeLi == 109 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(109)">
                        <div class="profile-inner-table-name">مقاومت 3 (R3)</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کلاسیک (Classic)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.classic.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">فیبوناچی (Fibonacci)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.fibonacci.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کاماریلا (Camarilla)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.camarilla.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">وودی (Woodie)</div>
                                <div class="profile-inner-table-value">{{ formatPrice(stock_support_and_resistance.woodie.r3) }}</div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دی مارک (DM)</div>
                                <div class="profile-inner-table-value">-</div>
                            </div>
                        </div> 
                    </div>
                </li>
            </ul>
        </div>
    </slot>


<!-- 
    حمایت و مقاومت<br>
    حمایت 3 (S3)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.s3 ? stock_support_and_resistance.classic.s3 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.s3 ? stock_support_and_resistance.fibonacci.s3 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.s3 ? stock_support_and_resistance.camarilla.s3 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.s3 ? stock_support_and_resistance.woodie.s3 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.s3 ? stock_support_and_resistance.demark.s3 : '-' }}<br>
    ##############################<br>
    حمایت 2 (S2)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.s2 ? stock_support_and_resistance.classic.s2 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.s2 ? stock_support_and_resistance.fibonacci.s2 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.s2 ? stock_support_and_resistance.camarilla.s2 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.s2 ? stock_support_and_resistance.woodie.s2 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.s2 ? stock_support_and_resistance.demark.s2 : '-' }}<br>
    ##############################<br>
    حمایت 1 (S1)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.s1 ? stock_support_and_resistance.classic.s1 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.s1 ? stock_support_and_resistance.fibonacci.s1 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.s1 ? stock_support_and_resistance.camarilla.s1 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.s1 ? stock_support_and_resistance.woodie.s1 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.s1 ? stock_support_and_resistance.demark.s1 : '-' }}<br>
    ##############################<br>
    نقطه پیوت (PP)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.pp ? stock_support_and_resistance.classic.pp : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.pp ? stock_support_and_resistance.fibonacci.pp : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.pp ? stock_support_and_resistance.camarilla.pp : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.pp ? stock_support_and_resistance.woodie.pp : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.pp ? stock_support_and_resistance.demark.pp : '-' }}<br>
    ##############################<br>
    مقاومت 1 (R1)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.r1 ? stock_support_and_resistance.classic.r1 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.r1 ? stock_support_and_resistance.fibonacci.r1 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.r1 ? stock_support_and_resistance.camarilla.r1 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.r1 ? stock_support_and_resistance.woodie.r1 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.r1 ? stock_support_and_resistance.demark.r1 : '-' }}<br>
    ##############################<br>
    مقاومت 2 (R2)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.r2 ? stock_support_and_resistance.classic.r2 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.r2 ? stock_support_and_resistance.fibonacci.r2 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.r2 ? stock_support_and_resistance.camarilla.r2 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.r2 ? stock_support_and_resistance.woodie.r2 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.r2 ? stock_support_and_resistance.demark.r2 : '-' }}<br>
    ##############################<br>
    مقاومت 3 (R3)<br>
    کلاسیک (Classic) : {{ stock_support_and_resistance.classic.r3 ? stock_support_and_resistance.classic.r3 : '-' }}<br>
    فیبوناچی (Fibonacci) : {{ stock_support_and_resistance.fibonacci.r3 ? stock_support_and_resistance.fibonacci.r3 : '-' }}<br>
    کاماریلا (Camarilla) : {{ stock_support_and_resistance.camarilla.r3 ? stock_support_and_resistance.camarilla.r3 : '-' }}<br>
    وودی (Woodie) : {{ stock_support_and_resistance.woodie.r3 ? stock_support_and_resistance.woodie.r3 : '-' }}<br>
    دی مارک (DM):{{ stock_support_and_resistance.demark.r3 ? stock_support_and_resistance.demark.r3 : '-' }}<br>
    ##############################<br> -->
    </div>
</template>

<script>
export default {
    name: 'StockIndicator',
    data() {
        return {
            stock_indicator : null,
            stock_indicator_values : null,
            stock_support_and_resistance : null,
            activeLi:0,
        }
    },
    mounted() {
        this.getProfileIndicator();
    },
    methods: {
        getProfileIndicator() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-indicator/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_indicator_values = api_response.data.response.indicator.body.response.data.indicator_values;
                        this.stock_support_and_resistance = api_response.data.response.indicator.body.response.data.support_and_resistance;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>